<template>
    <header class="header" id="header">
            <img class="logo" src="../assets/notaire.webp" alt="Logo des Notaires">
            <router-link to="/"><h1 id="title">Office Notarial de Maître Montagner</h1></router-link>
            <nav>
                <div class="conteneur-nav">
                    <input class="menu-btn" type="checkbox" id="menu-btn" v-model="checkedMenue"/>
                    <label class="menu-icon" for="menu-btn"><span id="navicon" class="navicon"></span></label>
                    <ul @click="uncheck(checkedMenue)" class="menu" id="menu">
                    <li><router-link to="/">Accueil</router-link></li>
                    <!--<li class="deroulant"><router-link to="Actualites">Actualités</router-link>
                      <ul class="sous">
                        <li><router-link to="Lettredemonnotaire">La Lettre de mon Notaire</router-link></li>
                        <li><router-link to="Conseils">Conseils des Notaires</router-link></li>
                      </ul>
                    </li>-->
                    <li class="deroulant"><router-link to="Prestations">Prestations</router-link>
                      <ul class="sous">
                        <li><router-link to="Missions">Missions</router-link></li>
                        <li><router-link to="Services">Services en ligne</router-link></li>
                        <li><router-link to="Guides">Guides pratiques</router-link></li>
                        <li><router-link to="Liensutiles">Liens Utiles</router-link></li>
                      </ul>
                    </li>
                    <li class="deroulant"><router-link to="Office">Notre Office</router-link>
                      <!--<ul class="sous">
                        <li><anchor-router-link :to="{name: 'Office', hash: '#presentation'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                        Présentation</anchor-router-link></li>
                        <li><anchor-router-link :to="{name: 'Office', hash: '#equipe'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">L'équipe</anchor-router-link></li>
                      </ul>-->
                    </li>
                    <li class="deroulant"><router-link to="Contact">Contact</router-link>
                        <!--<ul class="sous">
                          <li><a href="https://www.mondossiernotaire.fr/RdvEnLigne?">Prendre rendez-vous</a></li>
                        </ul>-->
                    </li>
                  </ul>
                </div>
              </nav>
        </header>
</template>

<script>
//import AnchorRouterLink from 'vue-anchor-router-link'

export default {
  name: 'Header',
  props: {
    msg: String
  }/*,
  components: {
    AnchorRouterLink
    },*/,
    data: function () {
     return {
         checkedMenue: false
     }
  },
    methods: {
    uncheck: function() {
      this.checkedMenue = !this.checkedMenue;
    } 
    }
}
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;



 /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 20px 15px;
    position: relative;
    user-select: none;
    background-color: $tertiary-color;
    border-radius: 10px;
  }
  
  .header .menu-icon .navicon {
    background: white;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: white;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }

  .naviconactive, .naviconactive:before, .naviconactive:after {
    background: #333!important;
  }
  
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    max-height: 480px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent!important;;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }


header {
	display: grid;
	width: 100%;
	grid-template-columns: 25% 50% 25%;
	grid-template-areas:  "logo title navigation";
    align-items: center;
	width: 100%;
	margin: auto;
    height: 80px;
    border-bottom: solid 1px gray;
    background-color: white;
    position: sticky;
    top: 0;
    box-shadow: 0px -8px 20px 0px #111;
    z-index: 2;
}

.logo {
    padding: 10px;
    box-sizing: border-box;
    height: inherit;
    grid-area: logo;
    margin: auto;
}

#title {
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    grid-area: title;
    font-size: large;
    text-align: center;
    @media screen and (max-width: 380px){
        font-size: small;
    }
}


nav{
    padding: 10px;
    box-sizing: border-box;
    grid-area: navigation;
    width: 100%;
}
.conteneur-nav{
    width: 100%;
    text-align: right;
}

nav ul{
    max-height: 0;
    overflow: hidden;
    list-style-type: none;
    background-color: lighten($color: $tertiary-color, $amount: 20);
    position: fixed;
    left: 0;
    top: 70px;
    width: 100%;
    padding: 0;
    transition: all 300ms;
}


nav ul li{
    flex: 1 1 auto;
    text-align: center;
}
nav > div > ul > li > a{
    color: black;
    border-top: 0.5px solid black;
}
nav a{
    display: block;
    text-decoration: none;
    color: black;
    padding: 10px 0px;
}
.sous{
    display: contents;
    z-index: 1000;
    padding: 5px;
    box-sizing: border-box;
    
}

.sous li{
    flex: 1 1 auto;
    text-align: left;
}
.sous a{
    padding: 10px;
    background-color: lighten($color: $tertiary-color, $amount: 30);
}

@media screen and (min-width: 980px){

    .header .menu-icon {
      display: none;
    }

    .header .menu {
      clear: none;
      float: right;
      max-height: none;
      margin-right: 20px;
    }
    .header .menu-icon {
      display: none;
    }

   .conteneur-nav{
        position: static;
    }
    nav label, nav input{
        display: none;
    }
    nav input[type=checkbox]:checked + ul, nav ul{
        display: flex;
        flex-flow: row wrap;
        background-color: white;
            margin-top: 0;
    }
    nav ul li{
        position: relative;
    }

    nav ul{
        position: inherit;
        padding: 0;
        max-height: none;
        overflow: visible;
        top: unset;
    }

    nav > div > ul > li > a{
        color: black;
        border: 0;
        border-bottom: 2px solid transparent;
    }
    nav a{
        border-bottom: 2px solid transparent;
    }
    nav a:hover{
        color: darken($color: $tertiary-color, $amount: 25);
        border-bottom: 2px solid $tertiary-color;
    }
    .sous{
        display: none;
        box-shadow: 0px 1px 2px #CCC;
        background-color: white;
        position: absolute;
        width: 100%;
    }
    nav > div > ul .deroulant:hover .sous{
        display: flex;
        flex-flow: column wrap;
    }
    .sous a{
        border-bottom: none;
        background-color: white;
    }
    .sous a:hover{
        border-bottom: none;
        background-color: RGBa(200,200,200,0.1);
    }
    header {
        display: grid;
        font-family: 'Quicksand', sans-serif;
        grid-template-columns: 130px 200px auto auto;
        grid-template-rows: 80px;
        grid-template-areas:  "logo title . navigation";
    }
    #title {
        text-align: left;
    }
}

</style>
<template>
  <div id="app-layout">
            <div class="home__title">
              <h2 class="home__title__bg">OFFICE NOTARIAL<br>DE MAÎTRE MONTAGNER</h2>
            </div>            
            <div class="content">
                <section class="bienvenue">
                  <img class="bienvenue__img" src="../assets/officehome2.webp" alt="Entrée vue de l'extérieur de l'office notarial de Maitre Montagner">
                  <h2 class="bienvenue__text"><span class="bienvenue__word">Bienvenue</span><br>à l'office notarial de<br>Maître Angélique Montagner<br> à Seignosse.</h2>
                </section>
                 <section class="video">
                  <video src="../assets/penonloop1.mp4" width="100%" height="250" preload muted="true" autoplay loop="true" class="homevid">
                    Cette vidéo ne peut être affichée sur votre navigateur Internet.<br>
                    Une version est disponible en téléchargement sous <a href="URL">adresse du lien </a> . 
                  </video>
                  </section>
                <section class="links">
                  <router-link to="Prestations" class="links__block">
                    <i class="fas fa-pencil-alt links__icon"></i>
                    <div class="links__text">
                      Consultez nos prestations
                    </div>
                  </router-link>
                  <router-link to="Office" class="links__block">
                    <i class="fas fa-home links__icon"></i>
                    <div class="links__text">
                      Découvrez notre office
                    </div>
                  </router-link>
                  <a href="./contact.php" class="links__block">
                    <i class="far fa-calendar-alt links__icon"></i>
                    <div class="links__text">
                      Nous contacter
                    </div>
                  </a>
                </section>
                
                <section class="contact">
                  <div class="contact__findus">
                    <div class="contact__contact">
                    <h3 class="contact__title">Nous trouver, nous contacter :</h3>
                    <ul class="contact__list">
                      <li class="contact__link"><i class="fas fa-map-marker-alt contact__icon"></i><p class="contact__link__text">12 Avenue de Laubian,<br>40510 Seignosse</p></li>
                      <li class="contact__link"><a class="contact__link__text" href="mailto:angelique.montagner@40058.notaires.fr"><i class="far fa-envelope contact__icon"></i>angelique.montagner@40058.notaires.fr</a></li>
                      <li class="contact__link"><a class="contact__link__text" href="tel:+33558583515"><i class="fas fa-phone contact__icon"></i>05 58 58 35 15</a></li>
                    </ul>
                    </div>
                    <div>
                    <h3 class="contact__title">Horaires :</h3>
                      <ul class="contact__list">
                        <li class="contact__link">Du lundi au vendredi,</li>
                        <li class="contact__link">de 8h45 à 12h30 et de 13h30 à 18h30</li>
                      </ul>
                  </div>
                  </div>
                  <div class="contact__horaires">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11540.488904522415!2d-1.3543598!3d43.6872223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcc212946c3aec03!2sOffice%20notarial%20de%20Me%20Ang%C3%A9lique%20Montagner!5e0!3m2!1sfr!2sfr!4v1613212714001!5m2!1sfr!2sfr" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                  </div>                  
                </section>
            </div>
  </div>
</template>

<script>
 /*import Header from '../components/header.vue';
  import Footer from '../components/footer.vue';*/

  export default {
    name: "Home",
    /*components: {
      Header,
      Footer
    }*/
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.content {
    width: 75%;
    margin: auto;
    padding-bottom: 10px;
    background-color: rgba(250,250,250,0.9);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.homevid {
  object-fit: cover;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 20px;
}

.home {
    display: block;
    &__title {        
        font-family: 'Quicksand', sans-serif;
        margin: 0;
        text-align: center;
        box-sizing: border-box;
        padding-top: 150px;
        padding-bottom: 150px;
        color: black;
        text-shadow: 0 0 1px $tertiary-color;
        &__bg {
            background-color: rgba(229, 172, 95, 0.8);
            margin: auto;
            box-sizing: border-box;
            padding: 15px;
            border-radius: 4px;
            width: fit-content;
        }
    }
}


.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
    @media screen and (max-width: 940px){
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;
         }
    &__title {
        margin-bottom: 15px;
    }
    &__list {
    list-style-type: none;
    @media screen and (max-width: 720px){
        padding: 0;
        width: fit-content;
        margin: auto;
         }
    }
    &__link {
        display: flex;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
        flex-direction: row;
        align-items: center;
        &__text {
            margin: 0;
            text-decoration: none;
            color: black;
            @media screen and (max-width: 399px){
                white-space: nowrap;
                font-size: smaller;
                 }
            &:hover {
                text-decoration: none;
                color: black;
            }
            &:visited {
                text-decoration: none; 
                color: black;
                 }
            }    
        }
    &__icon {
            margin-right: 10px;
        }
    &__findus {
        @media screen and (max-width: 940px){
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 690px){
          flex-direction: column;
          width: 95%;
          margin: auto;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 399px){
            overflow: hidden;
             }
    }
    &__horaires {
      width: 40%;
      @media screen and (max-width: 940px){
          width: 100%;
        }
    }
    &__contact {
      margin-bottom: 50px;
      @media screen and (max-width: 940px){
          margin-bottom: 10px;
        }
    }
}

.bienvenue {
    display: flex;
    align-items: center;
    @media screen and (max-width: 699px){
        flex-direction: column;
         }
    &__img {
        width: 50%;
        margin: 20px;
        border-radius: 5px;
        @media screen and (max-width: 699px){
            width: 95%;
            margin-top: 10px;
             }
    }
    &__text{
        text-align: center;
        margin: auto;
        font-weight: normal;
    }
    &__word {
        color: $tertiary-color;
    }
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 450px){
        flex-direction: column;
         }
    &__icon {
        font-size: 80px;
        color: $tertiary-color;
        margin-bottom: 20px;
    }
    &__block {
        text-align: center;
        margin: 0 10px;
        width: 30%;
        @media screen and (max-width: 450px){
            margin: 20px 10px;
            width: 80%;
             }
    }
    &__text {
    border: 0.5px solid;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 0px 0px $tertiary-color;
    transition: all 250ms;
    &:hover {
        background-color: lighten($color: $tertiary-color, $amount: 15);
    }
    }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/prestations',
    name: 'Prestations',
    component: () => import('../views/Prestations.vue')
  },
  {
    path: '/missions',
    name: 'Missions',
    component: () => import('../views/Missions.vue')
  },
  {
    path: '/guides',
    name: 'Guides',
    component: () => import('../views/Guides.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue')
  },
  {
    path: '/liensutiles',
    name: 'Liensutiles',
    component: () => import('../views/Liensutiles.vue')
  },
  {
    path: '/lettredemonnotaire',
    name: 'Lettredemonnotaire',
    component: () => import('../views/Lettredemonnotaire.vue')
  },
  {
    path: '/Office',
    name: 'Office',
    component: () => import('../views/Office.vue')
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue')
  },
  {
    path: '/mentionslegales',
    name: 'Mentionslegales',
    component: () => import('../views/Mentionslegales.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/Actualites',
    name: 'Actualites',
    component: () => import('../views/Actu.vue')
  },
  {
    path: '/Conseils',
    name: 'Conseils',
    component: () => import('../views/Conseils.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
